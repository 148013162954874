import CommonVariables from "./variables";

const statesArray = [400,401,403,404,500]
const customMessages = {
    "400":"Validation Error, Check fields and try again.",
    "401":"Resource not found",
    "403":"Unauthorised Access",
    "404":"Resource not found",
    "500":"Failed to connect Server, Try Again"
}

const APIConnector = {
    Get: async function (url) {
        let Accesstoken = (url === CommonVariables.urls.loginUrl) ? "" : ("Bearer " + CommonVariables.getlocalstorageData().accessToken);
        if (url !== CommonVariables.urls.loginUrl && url !== CommonVariables.urls.ForgotPasword) {
            if (!Accesstoken) {
                window.location.href = window.location.origin;
            }
        }
        // var configData = await CommonVariables.getConfigData();
        var response = await fetch(CommonVariables.urls.baseUrl + url, {
            method: "GET",
            headers: {
                // credentials: "include",
                "Content-Type": "application/json; charset=utf-8 ",
                Accept: "*/*",
                // "API-KEY": "secret",
                Authorization: Accesstoken,
            }
        });
        response = await response.json();
        return response;
    },

    Post: async function (url, data, token) {
        ;
        // let navigate  = useNavigate();
        try {
            data = data || {};
            ;
            let Accesstoken = (url === CommonVariables.urls.loginUrl) ? "" : ("Bearer " + token || CommonVariables.getlocalstorageData().token);
            if (url !== CommonVariables.urls.loginUrl) {
                if (CommonVariables.getlocalstorageData().token === undefined) {
                    // window.location.href = "/";
                    // navigate("/");

                }
            }
            // var configData = await CommonVariables.getConfigData();
            var response = await fetch(CommonVariables.urls.baseUrl + url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Accept": "*/*",
                    "Authorization": Accesstoken,
                },
                body: JSON.stringify(data),

            });
            var customMessage = ""
            if(statesArray.indexOf(response.status) >= 0){
                response = await response.json();
                response.customMessage = customMessages[response.status];
                return response
            }
            response = await response.json();

        }

        catch (error) {
            if (response)
                return response;
            return { isSuccess: false, customMessage: "Failed to fetch data from API" }
        }

        return response;
    },
    Update: async function (url, data) {
        let Accesstoken = (url === CommonVariables.urls.loginUrl) ? "" : ("Bearer " + CommonVariables.getlocalstorageData().accessToken);
        if (url !== CommonVariables.urls.loginUrl && url !== CommonVariables.urls.ForgotPasword) {
            if (!Accesstoken) {
                window.location.href = window.location.origin;
            }
        }
        var configData = await CommonVariables.getConfigData();
        var response = await fetch(configData.ApiUrl + url, {
            method: "PUT",
            headers: {
                credentials: "include",
                "Content-Type": "application/json; charset=utf-8 ",
                Accept: "*/*",
                "API-KEY": "secret",
                Authorization: Accesstoken,
            },
            body: JSON.stringify(data),
        });
        response = await response.json();
        return response;
    },
    Delete: async function (url) {
        var configData = await CommonVariables.getConfigData();
        var response = await fetch(configData.ApiUrl + url, { method: "DELETE" });
        response = await response.json();
        return response;
    },

    //1. Token
    //2. UserID
    //3.Base Domain
    FileUpload: async function (url, data) {
        try {
            data = data || {};

            let Accesstoken = (url === CommonVariables.urls.loginUrl) ? "" : ("Bearer " + CommonVariables.getlocalstorageData().token);
            if (url !== CommonVariables.urls.loginUrl) {
                if (CommonVariables.getlocalstorageData().token === undefined) {
                    window.location.href = "/";
                }
            }
            var response = await fetch(CommonVariables.urls.baseUrl + url, {
                method: "POST",
                headers: {
                    Accept: "*/*",
                    Authorization: Accesstoken,
                },
                body: data.files,

            });
            if(statesArray.indexOf(response.status) >= 0){
                response = await response.json();
                response.customMessage = customMessages[response.status];
                return response
            }
            response = await response.json();

        }

        catch (error) {
            if (response)
                return response;
            return { isSuccess: false, message: "Failed to upload file" }
        }

        return response;
    }
};
export default APIConnector;
