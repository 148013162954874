import React from 'react';
import RingLoader from "react-spinners/RingLoader";
import './spinner.css'

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#c26bf8",
    // zIndex: "999999999999",
};
const CommonLoading = (props) => {
  let color ="#c26bf8";
  return (
    <div className="sweet-loading loading-div d-flex align-items-center justify-content-center">
        <RingLoader
            color={color}
            loading={props.loading}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            // style={{"zIndex": "1000000"}}
        />
    </div>
  )
}

export default CommonLoading;