import React, { useState } from "react";
import $ from "jquery";
import book from "../../../images/icons8-book.gif";
import askHr from '../../../images/askHrHappy.png';
import cactus from '../../../images/cactus.png';
import CommonVariables from "../../../variables";
import APIConnector from "../../../apiconnector.js";
import "./login.css";
import Notifications from "../../../Notifications/Messages.js"
import { Link } from 'react-router-dom';
import { 
    // useSelector, 
    // useDispatch 
    } from 'react-redux';
import { getDomain } from "../../../domains.js";
import CommonLoading from "../../../Layout/CommonLoading.js";
import Button from "@mui/material/Button";
import { FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, TextField, Tooltip } from "@mui/material";
import { Padding, Visibility, VisibilityOff } from "@mui/icons-material";

function Login(props) {
    const hostedPort = '8085';
    const domain = getDomain(window.location.hostname);
    const [showPassword,setShowPassword] = useState(false);
    $(document).ready(function () {
        CommonVariables.clearlocalStorage();
    });
    const [loading, setLoading] = useState(false);
    // console.log(props.onLoaderRaise);
    const VerifyAndLogin = (event) => {
        event.preventDefault();
        var email = $("#username").val();
        const data = { userEmail: email, password: $("#Password").val() };
        props.onLoaderRaise(true, "Verifying login ...", { marginTop: "155px" });
        // setLoading(true);
        APIConnector.Post(CommonVariables.urls.loginUrl, data).then(async function (response) {
            if(response?.isFailure && response?.isFailure === true){
                setLoading(false);
                props.onLoaderRaise(false);
                Notifications.ShowErrorMessage(response.message);
                return;
            }
            else if(!response?.isSuccess  && response.isSuccess === false){
                setLoading(false);
                props.onLoaderRaise(false);
                Notifications.ShowErrorMessage(response.customMessage)
            }
            else {
                ;
                let tenantSubdomainParts = response?.data?.url?.split(".");
                let tenantSubdomain = tenantSubdomainParts?.slice(0,-2).join("");
                let redirectUrl = `http://${tenantSubdomain}.${domain}`;
                if(domain === 'localhost') {
                    redirectUrl = `http://${tenantSubdomain}.${domain}:${CommonVariables.port}`;
                }
                CommonVariables.setlocalStorageData({ url: response?.data?.url, token: response?.data?.token});
                if(response.data.user_Role[0] === 'TenantUser'){
                    if(response?.dbInitated === true){
                        window.location.href = `${redirectUrl}/dashboard?token=${response?.data?.token}`;
                        // setLoading(false);
                        // props.onLoaderRaise(false);
                    } else {
                        setLoading(false);
                        props.onLoaderRaise(false);
                        Notifications.ShowWarningMessage('User details do not exist!');
                    }
                } else if(response.data.user_Role[0] === 'TenantAdmin'){
                    if(response?.dbInitated === true){
                        // ;                       
                        // const params = new URLSearchParams();
                        // if (response?.data?.token) {
                        // params.append("token", response?.data?.token);
                        // } else {
                        // params.delete("token");
                        // }
                        window.location.href = `${redirectUrl}/dashboard/users?token=${response?.data?.token}`; 
                        // setLoading(false);
                        // props.onLoaderRaise(false);
                        // ;                     
                    } else {                           
                        window.location.href = `${redirectUrl}/dashboard/database?token=${response?.data?.token}`;    
                        // setLoading(false);
                    }
                } else if(response.data.user_Role[0] === 'SuperAdmin'){
                    window.location.href = `${redirectUrl}/dashboard/organisations?token=${response?.data?.token}`;    
                    // setLoading(false);
                    // props.onLoaderRaise(false);
                }
            }   
        });
    };

    return (
        <>
            {loading? <CommonLoading /> :
                <div className="login-body d-flex align-items-center justify-content-center">   
                    <div className="d-flex login-form p-4 rounded-4 shadow-lg fs-6 align-items-center justify-content-center">
                    <Paper sx={{padding:3,borderRadius:5}} elevation={3}>
                        <div className="d-flex flex-column login-form-div p-3 p-sm-0">
                            <form onSubmit={VerifyAndLogin} className="d-flex flex-column">
                                <div style={{"width":"100%","textAlign":"center"}} >
                                    <img src={book}  alt="book icon" className="animated-book-icon-login" width="50" height="55"  style={{"backgroundColor":"transparent"}}/>
                                </div>
                                <h3 
                                    className="login-p mt-2 pb-3 fs-6 text-secondary" 
                                    style={{"width": "100%"} }
                                >
                                    Sign In to 1stAskHR
                                </h3>
                                {/* <div className="mb-4 mt-3 ">
                                    <input
                                        type="text"
                                        autoFocus={true}
                                        autoComplete="username"
                                        className="ps-1 pt-1 pb-1 rounded-0 border-input w-100 d-block border-top-0 border-start-0 border-end-0 fs-6"
                                        id="username"
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div> */}
                                <FormControl  sx={{ m: 1}} variant="standard" className="mb-4 mt-3 ">
                                    <InputLabel htmlFor="username">Email</InputLabel>
                                        <Input
                                            id="username"
                                            label='Email'
                                            variant="standard"
                                            type='text'
                                            placeholder="Enter your email"
                                            required
                                        />
                                    </FormControl>
                                {/* <TextField 
                                    className="text-start mt-0 mb-4"
                                    type="text"
                                    id="username"
                                    label="Email"
                                    placeholder="Enter your email"
                                    variant="standard"
                                    required
                                    /> */}
                                <FormControl  sx={{ m: 1}}  variant="standard" className="mb-0 mt-3">
                                    <InputLabel htmlFor="Password">Password</InputLabel>
                                        <Input
                                            id="Password"
                                            label='Password'
                                            variant="standard"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Enter your password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={()=>setShowPassword(!showPassword)}>
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                                </InputAdornment>                                
                                            }
                                            required
                                        />
                                    </FormControl>
                                {/* <div className="mb-0 mt-3">
                                    <input
                                        type="password"
                                        autoComplete="current-password"
                                        className="ps-1 pt-1 pb-1 rounded-0 border-input w-100 d-block border-top-0 border-start-0 border-end-0"
                                        id="Password"
                                        placeholder="Enter your password"
                                        required
                                    />
                                </div> */}
                                <div className="d-flex justify-content-center">
                                    {/* <button
                                        type="submit" className="button-color px-3 mt-4 mb-3 hover:bg-sky-700 rounded-1">
                                        Login
                                    </button> */}
                                    <Button
                                        // color="primary"xxxxxx
                                        type="submit"
                                        variant="contained"
                                        style={{minWidth:"6rem",maxWidth:"12rem"}}
                                        className="px-3 mt-4 mb-3 button-color"
                                        >
                                        Login
                                    </Button>
                                </div>                   
                                { <div className="btns mt-4 float-sm-right">
                                    <Link to="/forgotpassword" className="text-decoration-underline mx-0 border-dark" style={{color:"#003087"}}>Forgot password?</Link>
                                </div> }
                            </form>
                        </div>
                        </Paper>
                        <div className="login-images d-lg-flex d-none">
                            <img src={askHr}  alt="askHr" className="askHr-image-login" width="300" height="300" style={{"backgroundColor":"transparent"}}/>
                            <img src={cactus}  alt="cactus" className="cactus-image-login mt-5" width="90" height="230" style={{"backgroundColor":"transparent"}}/>
                        </div>  
                    </div>
                </div>
            }   
        </>
    );
}
export default Login;