import { APP } from "./DomainHelpers";
import CommonVariables from "./variables";
// import APIConnector from "./apiconnector";
import Notifications from "./Notifications/Messages";
// import { useParams } from "react-router-dom/dist";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom/dist";
const statesArray = [400,401,403,404,500]
const customMessages = {
    "400":"Validation Error, Check fields and try again.",
    "401":"Resource not found",
    "403":"Unauthorised Access",
    "404":"Resource not found",
    "500":"Failed to connect Server, Try Again"
}
export const getApp = () => {
    const subdomain = getSubDomain(window.location.hostname);

    const main = APP.find((app) => app.main);

    if(!main) throw new Error ("Must have a main app");

    if (subdomain === "") return main.app;

    const app = APP.find((app) => subdomain === app.subdomain);

    if(!app) return main.app;
    return app.app;
}
export const getSubDomain = (location) => {
    // const location = window.location.hostname;
    const locationParts = location.split(".");

    let sliceTill = -2;

    const isLocalHost = locationParts.slice(-1)[0] === 'localhost';
    if(isLocalHost) sliceTill = -1;

    return locationParts.slice(0, sliceTill).join("");
}

export const getDomain = (location) => {
    // const location = window.location.hostname;
    const locationParts = location.split(".");

    let sliceTill = -2;

    const isLocalHost = locationParts.slice(-1)[0] === 'localhost';
    if(isLocalHost) sliceTill = -1;

    return locationParts.slice(sliceTill).join(".");
}

// const SubdomainHandleFunction = (url,data,token) => {
const InitialApiCall = async(url,data,token) => {
    // let navigate  = useNavigate();
    try {
        data = data || {};
        ;
        let Accesstoken = "Bearer " + token;
        // if (url !== CommonVariables.urls.loginUrl) {
        //     if (CommonVariables.getlocalstorageData().token === undefined) {
        //         // window.location.href = "/";
        //         // navigate("/");
        //         return { isSuccess: false, customMessage: "Unauthorized" };

        //     }
        // }
        // var configData = await CommonVariables.getConfigData();
        ;
        var response = await fetch(CommonVariables.urls.baseUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Accept": "*/*",
                "Authorization": Accesstoken,
            },
            body: JSON.stringify(data),

        });
        ;
        // var customMessage = ""
        if(statesArray.indexOf(response.status) >= 0){
            response = await response.json();
            response.customMessage = customMessages[response.status];
            return response
        }
        response = await response.json();

    }

    catch (error) {
        if (response)
            return response;
        return { isSuccess: false, customMessage: "Failed to fetch data from API" }
    }

    return response;
}
// }

function GetQueryParamObject(){
    ;
    var queryparam=window.location.search;
    if(queryparam){
        queryparam=queryparam.replace('?','');
        var eachQueryParam=queryparam.split("&")[0];
        var queryobject={}
        queryobject[eachQueryParam.split("=")[0]]=eachQueryParam.split("=")[1];
        return queryobject;
    }
    return false;
   
}


export const HandleSubdomain = async (props) => {
    // const { params } = useParams();
    // let dispatch = useDispatch();
    // let navigation = useNavigate();
    ;
    // console.log(window.location.pathname);
    // console.log(window.location.pathname.split("/"));
    const tokenObject = GetQueryParamObject();
    if(tokenObject && tokenObject?.token.length > 0){
        console.log(CommonVariables.urls.baseUrl);
        InitialApiCall(CommonVariables.urls.userLoggedInDataUrl, {}, tokenObject.token).then(async function (response) {
            ;
            // props.onLoaderRaise(false);
            if(response?.isFailure && response?.isFailure === true){
                Notifications.ShowErrorMessage(response.message);
                // setLoading(false);
                return;
            }
            else if(!response?.isSuccess  && response.isSuccess === false){
                Notifications.ShowErrorMessage(response.customMessage);
                return;
            }
            // if (!CommonVariables.validateAuthentication(response)) {
            //     return;
            // }
            else {
                // dispatch(setRoles({value:response.data.user_Role}))
                ;
                console.log(response?.data?.roles);
                CommonVariables.setlocalStorageData({ userRole: response?.data?.roles[0], url: response?.data?.url, token: tokenObject.token, userId: response?.data?.id, email: response?.data?.userEmail, userName: response?.data?.userName, tenantId:response?.data?.tenantId, dbInitated: response?.dbInitated});
                return true; 
                // window.location.href = `${redirectUrl}/${response?.data?.token}/dashboard/${props.navigate}`;
                // navigation(`/${localStorage.getItem("token")}/dashboard/users`);
            }
        }) 
    }   
}

   