import $ from 'jquery';
// import { fun } from '../App';
import { 
  // ThemeProvider, 
  createTheme
 } from '@mui/material/styles';
import { useLocation } from 'react-router-dom/dist';


const MakeLinkActive =(event, item) =>{
  // let location = useLocation();
  // const urlPath = location.pathname;
  // const parts = urlPath.split('/');
  // const endingPart = parts[parts.length - 1];
  // console.log(endingPart);
  let storageKey = 'sideMenuItemKey';
  $(".active-link").removeClass("active-link");
  $(".chevron-right-arrow").addClass("d-none");
  localStorage.setItem(storageKey, event.currentTarget.innerText.trim());
  let storedItem = localStorage.getItem(storageKey).toLocaleLowerCase();
  if(storedItem.includes(item)){
    event.currentTarget.classList.add("active-link");
    event.currentTarget.children[1].classList.remove("d-none");
    event.currentTarget.children[1].classList.add("d-block");
  }
    // console.log(event.currentTarget);
}

const toggleTheme =() =>{
    $(".lower-div").attr("data-bs-theme") === "light"?( $(".lower-div").attr("data-bs-theme","dark")): $(".lower-div").attr("data-bs-theme","light");
    $("ThemeProvider").attr("theme") === "lightTheme"?( $("ThemeProvider").attr("theme","darkTheme")): $("ThemeProvider").attr("theme","lightTheme");
}

const materialThemes = {
     darkTheme : createTheme({
        palette: {
          mode: 'dark',
        },
      }),
     lightTheme : createTheme({
        palette: {
          mode: 'light',
        },
      })
}

function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
  
    return (
      <div role="alert" className='p-2'>
        <div className='d-flex'>
        <span className="material-symbols-outlined text-danger pe-2 fs-2">error</span>
        <p className='text-danger fs-5'>Something went wrong:</p>
        </div>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }


export {MakeLinkActive,toggleTheme , materialThemes, fallbackRender}
