import React from 'react';
import { getDomain } from '../../domains';
import CommonVariables from '../../variables';
import { ErrorBoundary } from 'react-error-boundary';
import { CssBaseline } from '@mui/material';
import { motion } from "framer-motion"; 
import { fallbackRender } from '../../API/commonFunctions';
import askHr from '../../images/askHr.png';

const ErrorPage = () => {
    const domain = getDomain(window.location.hostname);
    let redirectUrl = `http://${domain}`;
    if(domain === 'localhost') {
        redirectUrl = `http://${domain}:${CommonVariables.port2}`;
    }
    const handleClick = () => {
        window.location.href=redirectUrl;
    }
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={() => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
        <div 
            className="p-1 for-transition w-100 h-100 d-flex flex-column align-items-center justify-content-center text-danger"
            style={{minHeight: '500px'}}
        >
            <img className="mt-5" src={askHr} alt='askHr' style={{height: '300px', width: '300px'}}></img>
            <p className='fs-2'>Uh oh! The page you're looking for does not exist.</p>
            <div 
                className='text-primary text-decoration-underline fs-8' 
                onClick={handleClick}                     
                style={{ cursor: 'pointer' }}
            >
                Go back to login
            </div>
        </div>
    </ErrorBoundary>
  )
}

export default ErrorPage;