import React from 'react';
// import Dashboardlayout from './Dashboardlayout';
import Loginlayout from './Loginlayout';

const Layout = props => {
    return (
        <Loginlayout RouterInfo={props} />
    ) 
};

export default Layout;
