import Notifications from "./Notifications/Messages";
// import { getDomain } from "./domains";
// const domain = getDomain(window.location.hostname);
const port = '3001';
const port2 = '3000';
const urls =
{
    // baseUrl: localStorage.getItem('url') !== 'null' ? `http://${localStorage.getItem('url')}/1staskhr/` : "http://1staskhr.com/1staskhr/",
    baseUrl: "http://1staskhr.com/1staskhr/",
    registerUrl: "api/Auth/register",
    loginUrl: "api/Auth/Login",
    userLoggedInDataUrl: "api/User/GetLoggedInUserData",
    forgotPassword: "api/Auth/Forgotpassword",

    //BookMate urls
    chatUrl: "api/Chat/QAChat",
    createSessionUrl: "api/Chat/CreateSession",
    renameSessionUrl: "api/Chat/UpdateSession",
    deleteSessionUrl: "api/Chat/DeleteSession",
    getSessionsUrl: "api/Chat/GetUserSessionsDetails",
    getSessionById: "api/Chat/GetSessionById",
    
    /***************  ORGANISATION URLS  ********************/
    getAllOrganisationsUrl: "api/Tenant/GetAllTenants",
    getOrganisationByIdUrl: "api/Tenant/GetTenantById",
    updateOrganisationUrl:"api/Tenant/UpdateTenant",
    deleteOrganisationUrl: "api/Tenant/DeleteTenant",
    addOrganisationUrl: "api/Tenant/CreateTenant",
    addOrganisationAdminUrl : "api/User/RegisterTenantAdmin",

    /*************** DATABASE URLS *************************/
    createOrganisationDatabaseUrl: "api/TenantDatabase/CreateTenantDB",
    updateOrganisationDatabaseUrl: "api/TenantDatabase/UpdateTenantDB",
    deleteOrganisationDatabaseUrl: "api/TenantDatabase/DeleteTenantDB",
    getOrganisationDatabaseByIdUrl: "api/TenantDatabase/GetDBByTenantId",
    
    /***************  USER URLS  ********************/
    getAllUsersUrl: "api/User/GetAllTenantUsers",
    getUserByIdUrl: "api/User/GetUserById",
    addUsersUrl: "api/User/RegisterTenantUser",
    updateUserUrl: "api/User/UpdateTenantUser",
    deleteUserUrl: "api/User/DeleteTenantUser",
    
    /***************  USER GROUPS URLS  ********************/
    getAllUserGroupsUrl: "api/UserGroup/GetAllTenantUserGroups",
    updateUserGroupUrl: "api/UserGroup/UpdateUserGroup",
    deleteUserGroupUrl: "api/UserGroup/DeleteUserGroup",
    addUserGroupUrl: "api/UserGroup/CreateUserGroup",
    getAllUserGroupUsersUrl: "api/User/GetAllUserGroupUsers",
    getAllUserGroupFilesUrl: "api/UserGroup/GetAllUserGroupDocuments",
    assignUsersToUserGroupUrl: "api/UserGroup/AssignUsersToGroup",
    
    /***************  FILES URLS  ********************/
    fileUploadUrl: "api/TenantDocument/FileUpload",
    getAllFilesUrl:"api/TenantDocument/GetAllTenantDocuments",
    deleteFileUrl:"api/TenantDocument/DeleteDocument"

};

const CommonVariables = {
    port: port,
    port2: port2,
    urls: urls,
    ConfigData: undefined,
    getConfigData: async function () {
        if (!this.ConfigData) {
            var ConfigResponse = await fetch('./config.json');
            this.ConfigData = await ConfigResponse.json();
            return this.ConfigData;
        }
        else
            return this.ConfigData;
    },
    logout: function () {
        localStorage.clear();
        window.location.pathname = "\\";
        // let redirectUrl = `http://${domain}`;
        // if(domain === 'localhost') {
        //     redirectUrl = `http://${domain}:${port}`;
        // }
        // window.location.pathname = redirectUrl;
        // localStorage.clear();
    },
    localstorageData: {},
    setlocalStorageData: function (storageData) {
        this.localstorageData = {
            userId: localStorage.setItem("userId", storageData.userId ? storageData.userId : localStorage.getItem("userId")),
            token: localStorage.setItem("token", storageData.token ? storageData.token : localStorage.getItem("token")),
            email: localStorage.setItem("email", storageData.email ? storageData.email : localStorage.getItem("email")),
            userName: localStorage.setItem("userName", storageData.userName ? storageData.userName : localStorage.getItem("userName")),
            currSessionId: localStorage.setItem("currSessionId", ""),
            currSessionName: localStorage.setItem("currSessionName", ""),
            tenantId: localStorage.setItem("tenantId", storageData.tenantId?  storageData.tenantId: localStorage.getItem("tenantId")),
            dbInitated: localStorage.setItem("dbInitated", storageData.dbInitated === false || storageData.dbInitated === true? storageData.dbInitated : localStorage.getItem("dbInitated")),
            url: localStorage.setItem("url", storageData.url? storageData.url : localStorage.getItem("url")),
            userRole: localStorage.setItem("userRole", storageData.userRole ? storageData.userRole : localStorage.getItem("userRole")),
        }
    },
    clearlocalStorage: function () {
        localStorage.clear();
    },
    getlocalstorageData: function () {
        return localStorage;
    },
    verifyLocalstorageDataAvailable: function () {
        let StorageData = this.getlocalstorageData();
        if (!StorageData.userId || !StorageData.accessToken || !StorageData.refreshToken || !StorageData.email) {
            return false;
        }
        return true;
    },
    factoryData: {},
    getFactoryData: function () {
        return this.factoryData;
    },
    setFactoryData: function (factoryData) {
        this.factoryData = factoryData;
    },
    isError: function (response) {
        if (response && response.isSuccess === false) {
            if (response.message)
                Notifications.ShowErrorMessage(response.message);
            else if (response.error)
                Notifications.ShowErrorMessage(response.error);
            else
                Notifications.ShowErrorMessage("Something went wrong.Please try again");
            return false;
        }
        if (response && response.status && response.status !== "200") {
            Notifications.ShowErrorMessage("Something went wrong.Please try again");
            return false;
        }
        return true;
    },
    validateAuthentication: function (response) {
        console.log(response);
        if (response) {
            if (response.status === 400) {
                Notifications.ShowErrorMessage("Invalid Credentials");
                return false;
            }
            else if (response.status === 404) {
                Notifications.ShowErrorMessage("User Not Found");
                return false;
            }
            else if (response.token)
                return true;
            else {
                Notifications.ShowErrorMessage("Something went wrong. Please try again");
                return false;
            }
        }
        else {
            Notifications.ShowErrorMessage("Something went wrong.Please try again");
            return false;
        }
    }
}
export default CommonVariables;