import React, 
    { 
        useState, 
        // useEffect 
    } from "react";
import "@progress/kendo-theme-default/dist/all.css";
import Loginrouting from "./Routing/LoginRouting";
import Layout from "./Layout/Layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useCallback } from 'react';
import CommonAlert from "./Layout/CommonAlert";
import CommonLoading from "./Layout/CommonLoading.js";

function Routing() {
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [LoaderMessage, setLoaderMessage] = useState("");
    const [LoaderStyles, setLoaderStyles] = useState({});

    const [isAlert, setisAlert] = useState(false)
    const [alertDataObj, setalertDataObj] = useState({});

    const onLoaderRaise = useCallback((flag, msg, LoaderStyles) => {
        ;
        setLoading(true);
        if (flag) {
            setLoading(false);
            setIsLoading(flag);
            if (!msg)
                msg = "Loading..."
            if (!LoaderStyles)
                LoaderStyles = { marginTop: "0px" };
            setLoaderStyles(LoaderStyles);
            setLoaderMessage(msg);
        }
        else {
            setLoading(false);
            setIsLoading(flag);
        }
    }, [isLoading]);

    const onAlert = useCallback((flag,dataObj) => {
        if (flag) {
            if (!dataObj) {
                setisAlert(false);
                return;
            }
            setisAlert(flag);
            setalertDataObj(dataObj);
        }
        else {
            setalertDataObj({});
            setisAlert(flag);
        }
    }, [isAlert]);

    return (
        <div className="h-100 m-0" >
            <div>
                <ToastContainer style={{ zIndex: "1000005" }} autoClose={5000} />
            </div>
            {isAlert && <CommonAlert alertDataObj={alertDataObj} />}
            <Layout onLoaderRaise={onLoaderRaise} onAlert={onAlert}>
                <div style={{"minHeight":"100vh" ,"background":"linear-gradient(90deg, rgb(241, 172, 236) 0%, rgb(202, 173, 240) 34%, rgb(171, 220, 237) 62%, rgb(148, 207, 231) 89%)"}}>
                    {isLoading || loading? <CommonLoading message={LoaderMessage} LoaderStyles={LoaderStyles} /> :
                        <Loginrouting onLoaderRaise={onLoaderRaise} />
                    }
                </div>
            </Layout>
        </div>
    );
}

export default Routing; 