import React
// , { useState } 
from 'react';
import { Routes, Route } from "react-router-dom";
import Login from "../Login/components/loginPage/loginpage";
import ErrorPage from '../Login/components/ErrorPage';
import Forgotpassword from "../Login/components/forgotpassword";
import CommonLoading from '../Layout/CommonLoading';
/**
 * 
 * This component contains all login routes
 * 
 */

function Loginrouting(props) {
    return (
        <Routes>              
            <Route index element={<Login onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path="/forgotpassword" element={<Forgotpassword onLoaderRaise={props.onLoaderRaise} />} />
            <Route exact path = "*" element={<ErrorPage />} />
        </Routes>
    );
}

export default Loginrouting;