import React, { useState } from 'react';
import "./App.css";
import { getApp } from './domains';
// import { useEffect } from 'react';
// import { HandleSubdomain } from './domains';
import CommonLoading from './Layout/CommonLoading';
// import Routing from './Routing';

const App = () => {
  const CurrentRoute = getApp();
  const [loading, setLoading] = useState(false);
  // console.log({CurrentRoute });
  // const changeSubdomain = async() => {
  //   ;
  //   setLoading(true);
  //   await HandleSubdomain();
  //   setLoading(false);
  // }
  // useEffect(()=>{
  //   changeSubdomain();
  // },[]);
  return (
    // <BrowserRouter>
      // <Provider store={store}>
      <div style={{"height":"100vh" ,"background":"linear-gradient(90deg, rgb(241, 172, 236) 0%, rgb(202, 173, 240) 34%, rgb(171, 220, 237) 62%, rgb(148, 207, 231) 89%)"}}>
          {loading? <CommonLoading /> :
            <CurrentRoute />
          }
      </div>
      // </Provider>
    // </BrowserRouter>
  )
}

export default App;
