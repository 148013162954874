import React from "react";

/**
 * This component is used to display alerts
 * 
 */
export default function CommonAlert(props) {
    return (
        <div className="k-dialog-wrapper" tabindex="0" style={{ zIndex: 100000000002 }} >
            <div className="k-overlay"></div>
            <div className="k-widget k-window kCommonAlertdialog" role="dialog">
                <div className="k-window-titlebar k-dialog-titlebar k-header">
                    <div className="k-window-title k-dialog-title" style={{ textAlign: "center", justifyContent: "center" }}>{props.alertDataObj.title}</div>
                </div>
                <div className="k-window-content k-dialog-content">
                    <p style={{ margin: "25px", textAlign: "center" }} className="fieldlabel">{props.alertDataObj.body}</p>
                </div>
                <div className="k-dialog-buttongroup k-actions k-hstack k-justify-content-stretch">
                    <div className="m-auto w-responsive" style={{ textAlign: "center" }}>
                      {!props.alertDataObj.displayNo && <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={props.alertDataObj.No}>{props.alertDataObj.NoTitle}</button>}
                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base ml-2"
                            onClick={() => { props.alertDataObj.Yes(props.alertDataObj.datatoPassOnYes) }}>{props.alertDataObj.YesTitle}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}