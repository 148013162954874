import React, { useState } from "react";
import APIConnector from "../../apiconnector";
import Notifications from "../../Notifications/Messages.js"
import { Link ,useNavigate } from 'react-router-dom';
import book from "../../images/icons8-book.gif";
import askHr from '../../images/askHrHappy.png';
import cactus from '../../images/cactus.png';
import CommonVariables from "../../variables.js";
import Button from "@mui/material/Button";
import { FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, TextField, Tooltip } from "@mui/material";
import $ from "jquery";

function Forgotpassword(props) {
    const navigation = useNavigate();
    const toemailsent = () => {
        //const regEx = /^\w+(?:[\.!#%&'*=+\-`{|}~]\w+)*@\w+(?:[\.!#%&'*=+\-`{|}~]\w+)*(?:\.\w{2,3})+$/;
        var username = $("#username").val();
        if (!username || username.trim().length <= 0) {
            Notifications.ShowWarningMessage("Please enter valid email.");
            $("#username").focus();
            return;
        }
       
        let data = {
            email: username
        };

        props.onLoaderRaise(true, "Verifying email ...", { marginTop: "155px" });

        APIConnector.Post(CommonVariables.urls.forgotPassword, data).then(async function (response) {
            props.onLoaderRaise(false);
            if (!response.IsPasswordReset) {
                Notifications.ShowErrorMessage("Something went wrong.Please try again.");
                return;
            }
            Notifications.ShowSuccessMessage("A new password has been sent to your email.");
            navigation('/');
           
        });
    };

    return (
         <div className="login-body d-flex align-items-center justify-content-center">   
                    <div className="d-flex login-form p-4 rounded-4 shadow-lg fs-6 align-items-center justify-content-center">
                    <Paper sx={{padding:3,borderRadius:5}} elevation={3}>
                        <div className="d-flex flex-column login-form-div p-3 p-sm-0">
                            <form className="d-flex flex-column">
                                <div style={{"width":"100%","textAlign":"center"}} >
                                    <img src={book}  alt="book icon" className="animated-book-icon-login" width="50" height="55"  style={{"backgroundColor":"transparent"}}/>
                                </div>
                                <h3 
                                    className="login-p mt-2 pb-3 fs-6 text-secondary" 
                                    style={{"width": "100%"} }
                                >
                                   Forgot Password
                                </h3>                             
                                <FormControl  sx={{ m: 1}} variant="standard" className="mb-4 mt-3 ">
                                    <InputLabel htmlFor="username">Email</InputLabel>
                                        <Input
                                            id="username"
                                            label='Email'
                                            variant="standard"
                                            type='text'
                                            placeholder="Enter your email"
                                            required
                                        />
                                    </FormControl>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        type="submit" onClick={toemailsent}
                                        variant="contained"
                                        style={{minWidth:"6rem",maxWidth:"12rem"}}
                                        className="px-3 mt-4 mb-3 button-color"
                                        >
                                        Submit
                                    </Button>
                                </div>                   
                                { <div className="btns mt-4 float-sm-right">
                                    <Link to="/" style={{ display: "block" }} className="text-decoration-none text-primary mt-4 rounded-0 mx-0">Already have an account? Login</Link>

                                </div> }
                            </form>
                        </div>
                        </Paper>
                        <div className="login-images d-lg-flex d-none">
                            <img src={askHr}  alt="askHr" className="askHr-image-login" width="300" height="300" style={{"backgroundColor":"transparent"}}/>
                            <img src={cactus}  alt="cactus" className="cactus-image-login mt-5" width="90" height="230" style={{"backgroundColor":"transparent"}}/>
                        </div>  
                    </div>
                </div>
           
    );
}
export default Forgotpassword;